<template>
  <component
    :is="type !== null ? 'a' : NuxtLink"
    :target="isExternal ? '_blank' : ''"
    :rel="isExternal ? 'noopener noreferrer nofollow' : ''"
    :class="{'link' : withDefaultStyling}"
    v-bind="{[type !== null ? 'href' : 'to']: to}"
  >
    <UiIcon
      v-if="icon"
      class="shrink-0"
      :name="icon"
      size="small"
    />

    <template v-if="responsiveLabel">
      <span class="sm:hidden">{{ responsiveLabel }}</span>
      <span class="max-sm:hidden"><slot>{{ label }}</slot></span>
    </template>
    <slot v-else>
      {{ label }}
    </slot>
  </component>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';
import {NuxtLink} from '#components';

const props = withDefaults(defineProps<{
  label?: string
  to: RouteLocationRaw,
  withDefaultStyling?: boolean,
}>(), {
  label: undefined,
  withDefaultStyling: true,
});

const isExternal = computed(() => typeof props.to === 'string' && props.to.match(/^https?:\/\//) && !props.to.includes('mozaiek.nl'));
const type = computed(() => {
  if (typeof props.to !== 'string') {
    return null;
  }

  if (props.to.match(/^mailto:/)) {
    return 'email';
  } else if (props.to.match(/^tel:/)) {
    return 'tel';
  }

  return null;
});

const icon = computed(() => ({
  email: 'mail',
  tel: 'mobile-phone',
})[type.value as string] ?? null);

const responsiveLabel = computed(() => ({
  email: 'mail ons',
  tel: 'bel ons',
})[type.value as string] ?? null);
</script>
